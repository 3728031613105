import styled from "@emotion/styled"
import React from "react"
import Author from "../atoms/Author"
import Date from "../atoms/Date"

const PostInfo = ({ date, author, author_detail }) => {
  return (
    <Container>
      <Date>{date.start}</Date>
      <Devider></Devider>
      <Author authors={author} author_detail={author_detail} />
    </Container>
  )
}

export default PostInfo

const Container = styled.section`
  display: flex;
  /* ${props => (props.isPost ? "justify-content: center;" : null)} */
  text-decoration: none;
  color: gray;
  font-size: 14px;
  align-items: center;
  margin: 16px 0;

  ${({ theme }) => theme.tablet` 
    margin: 20.5px 0;
  `};
`

const Devider = styled.div`
  width: 2px;
  height: 18px;
  border-right: #d4d4d4 2px solid;
  margin: 0 18px;
`
