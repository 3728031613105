import styled from "@emotion/styled"
import React from "react"
import Banner from "../organisms/Banner"
import PostList from "../organisms/PostList"
import ListTemplate from "../templates/list_template"

const Main = ({ pages }) => {
  return (
    <Container>
      <Banner />
      <ListTemplate pages={pages} />
    </Container>
  )
}

export default Main

const Container = styled.section`
  display: flex;
  flex-direction: column;
  align-items: center;
`
