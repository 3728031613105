import React from "react"
import { Global, css } from "@emotion/react"

const GlobalStyle = () => {
  return <Global styles={style} />
}

export default GlobalStyle

const style = css`
  * {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    font-family: "Pretendard";
  }

  body {
    width: 100%;
    height: 100%;
    box-sizing: border-box;
    margin: 0;
    font-family: "Pretendard";
  }

  @font-face {
    font-family: "Pretendard";
    font-weight: 900;
    font-display: swap;
    src: url("../fonts/Pretendard/Pretendard-Black.otf");
  }

  @font-face {
    font-family: "Pretendard";
    font-weight: 800;
    font-display: swap;
    src: url("../fonts/Pretendard/Pretendard-ExtraBold.otf");
  }

  @font-face {
    font-family: "Pretendard";
    font-weight: 700;
    font-display: swap;
    src: url("../fonts/Pretendard/Pretendard-Bold.otf");
  }

  @font-face {
    font-family: "Pretendard";
    font-weight: 600;
    font-display: swap;
    src: url("../fonts/Pretendard/Pretendard-SemiBold.otf");
  }

  @font-face {
    font-family: "Pretendard";
    font-weight: 500;
    font-display: swap;
    src: url("../fonts/Pretendard/Pretendard-Medium.otf");
  }

  @font-face {
    font-family: "Pretendard";
    font-weight: 400;
    font-display: swap;
    src: url("../fonts/Pretendard/Pretendard-Regular.otf");
  }

  @font-face {
    font-family: "Pretendard";
    font-weight: 300;
    font-display: swap;
    src: url("../fonts/Pretendard/Pretendard-Light.otf");
  }

  @font-face {
    font-family: "Pretendard";
    font-weight: 200;
    font-display: swap;
    src: url("../fonts/Pretendard/Pretendard-ExtraLight.otf");
  }

  @font-face {
    font-family: "Pretendard";
    font-weight: 100;
    font-display: swap;
    src: url("../fonts/Pretendard/Pretendard-Thin.otf");
  }
`

export const Colors = {
  default: "#858585",
  gray: "#ced4da",
  brown: "#d5bdaf",
  orange: "rgba(217, 115, 13, 1)",
  yellow: "#fff3b0",
  green: "#b7e4c7",
  blue: "#ade8f4",
  purple: "#dfe7fd",
  pink: "#ffccd5",
  red: "#eb5757",
}
