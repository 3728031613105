import React from "react"
import styled from "@emotion/styled"
import { TagsColor } from "../templates/GlobalStyle"

const Tags = ({ tags }) => {
  return (
    <>
      {tags.map(tag => (
        <Tag key={tag.id} color={tag.color}>
          {tag.name}
        </Tag>
      ))}
    </>
  )
}

export default Tags

const Tag = styled.span`
  padding: 6px 14px;
  margin-right: 8px;
  border-radius: 24px;
  background-color: #f1f1f1;
  font-size: 14px;
  color: #333333;
  user-select: none;
`
