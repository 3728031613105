import React from "react"
import BannerInfo from "../molecules/BannerInfo"
import styled from "@emotion/styled"
import mobileBanner from "../../images/mobile-banner.png"

const Banner = props => {
  return (
    <Container mobileUrl={mobileBanner}>
      <BannerInfo />
    </Container>
  )
}

export default Banner

const Container = styled.section`
  width: 100%;
  height: 300px;
  display: flex;
  justify-content: center;
  background-image: url(${props => props.mobileUrl});
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;

  ${({ theme }) => theme.tablet` 
    height: 440px;  
  `};

  ${({ theme }) => theme.laptop` 
    background-image: url("../../../laptop-banner.png"); 
  `};
`
