import styled from "@emotion/styled"
import React from "react"

const ProfileImage = ({ imageUrl }) => {
  return <Image src={imageUrl} alt="author_profile" />
}

export default ProfileImage

const Image = styled.img`
  width: 30px;
  height: 30px;
  border-radius: 50%;
  border: 1px solid lightgray;
`
