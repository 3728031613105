import React, { useEffect } from "react"
import { useStaticQuery, graphql } from "gatsby"
import styled from "@emotion/styled"
import Main from "../components/pages/Main"
import GlobalStyle from "../components/templates/GlobalStyle"
import Layout from "../components/templates/Layout"

const IndexPage = () => {
  const {
    allNotion: { edges },
  } = useStaticQuery(query)

  return (
    <Layout>
      <GlobalStyle />
      <Main pages={edges} />
    </Layout>
  )
}

export default IndexPage

const Container = styled.main`
  margin: 0;
`

export const query = graphql`
  query Myquery {
    allNotion(sort: { fields: createdAt, order: DESC }) {
      edges {
        node {
          childrenMarkdownRemark {
            html
            tableOfContents
            id
            fields {
              slug {
                title
                summary
                author {
                  id
                  name
                  avatar_url
                }
                author_detail
                tags {
                  id
                  name
                  color
                }
                date {
                  start(formatString: "YYYY.MM.DD")
                }
              }
            }
            frontmatter {
              title
              summary
              author {
                id
                name
                avatar_url
              }
              author_detail
              tags {
                id
                name
                color
              }
              date {
                start(formatString: "YYYY년 MM월 DD일")
              }
            }
          }
        }
      }
    }
  }
`
