import styled from "@emotion/styled"
import React from "react"

const Date = ({ children }) => {
  return <Time dateTime={children}>{children}</Time>
}

export default Date

const Time = styled.time`
  color: #8b8b8b;
`
