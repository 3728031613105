import React from "react"
import { Link } from "gatsby"
import styled from "@emotion/styled"
import ListTitle from "../atoms/ListTitle"
import PostInfo from "../molecules/PostInfo"
import Summary from "../atoms/Summary"
// import defaultProfile from "../../images/default-profile.png"
import ProfileImage from "../atoms/ProfileImage"
import Tags from "../atoms/Tags"

const PostList = ({ pages }) => {
  const pageList = pages.map(page => {
    const {
      node: { childrenMarkdownRemark },
    } = page

    const pageInfo = childrenMarkdownRemark[0]

    return {
      frontmatter: pageInfo.frontmatter,
      fields: pageInfo.fields,
      id: pageInfo.id,
    }
  })

  return (
    <>
      {pageList.map(
        ({
          frontmatter: { author, author_detail, date, tags, title, summary },
          fields,
          id,
        }) => (
          <Container key={id}>
            <PageLink to={fields.slug.title}>
              <TextSection>
                <ListTitle>{title}</ListTitle>
                <PostInfo
                  author={author}
                  author_detail={author_detail}
                  date={date}
                />
              </TextSection>
            </PageLink>
            <Summary summary={summary} />
            <Tags tags={tags} />
            <Divider />
          </Container>
        )
      )}
    </>
  )
}

export default PostList

const Container = styled.article`
  text-decoration: none;
  width: 100%;
  margin-top: 40px;

  ${({ theme }) => theme.tablet` 
    margin-top: 56px;
  `};
`

const PageLink = styled(Link)`
  display: flex;
  text-decoration: none;
  width: 100%;
`

const TextSection = styled.section`
  display: flex;
  flex-direction: column;
  flex: 5;
`

const Divider = styled.hr`
  border-top: 1px solid #ebebeb;
  margin-top: 40px;

  ${({ theme }) => theme.tablet` 
    margin-top: 56px;
  `};
`
