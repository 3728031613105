import styled from "@emotion/styled"
import React from "react"
import ProfileImage from "./ProfileImage"

const Author = ({ authors, author_detail }) => {
  return (
    <>
      {authors.map(author => {
        const isRealAuthor =
          author.name === author_detail || author_detail === ""
        return (
          <AuthorWrapper key={author.id}>
            <ProfileImage
              imageUrl={
                isRealAuthor
                  ? author.avatar_url
                  : `../../../${author_detail}.png`
              }
            />
            <Name>{isRealAuthor ? author.name : author_detail}</Name>
          </AuthorWrapper>
        )
      })}
    </>
  )
}

export default Author

const AuthorWrapper = styled.span`
  margin: 0;
  display: flex;
  align-items: center;
`

const Name = styled.strong`
  font-weight: 500;
  font-size: 14px;
  color: black;
  margin-left: 10px;
`
