import React from "react"
import styled from "@emotion/styled"

const ListTitle = ({ children }) => {
  return <Title>{children}</Title>
}

export default ListTitle

const Title = styled.h3`
  text-decoration: none;
  font-size: 20px;
  font-weight: 700;
  line-height: 1.5;
  color: #333333;

  ${({ theme }) => theme.tablet` 
    font-size: 24px;
    line-height: 1.4; 
  `};
`
