import styled from "@emotion/styled"
import React from "react"

const Summary = ({ summary }) => {
  return <SummaryWrapper>{summary}</SummaryWrapper>
}

export default Summary

const SummaryWrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  margin-bottom: 24px;
  word-break: break-all;
  color: #333333;
  // Markdown Style
  line-height: 1.4;
  font-size: 16px;
  font-weight: 400;
`
