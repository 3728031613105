import React from "react"
import Button from "../atoms/Button"
import rightArrow from "../../images/icon-right-arrow.png"
import styled from "@emotion/styled"

const CtaButton = ({ onClick }) => {
  return (
    <Button onClick={onClick}>
      개발자 채용공고 보기
      <ArrowIcon src={rightArrow} alt="right-arrow" />
    </Button>
  )
}

export default CtaButton

const ArrowIcon = styled.img`
  width: 16px;
  height: 16px;
  margin-left: 8px;

  ${({ theme }) => theme.tablet` 
    width: 24px;
    height: 24px;  
    margin-left: 4px;
  `};
`
