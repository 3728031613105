import styled from "@emotion/styled"
import React from "react"

const Button = ({ onClick, children }) => {
  return <BasicBtn onClick={onClick}>{children}</BasicBtn>
}

export default Button

const BasicBtn = styled.button`
  padding: 5px 14px 10px 14px;
  border-radius: 24px;
  border: none;
  font-size: 12px;
  color: white;
  font-weight: 500;
  background: -webkit-linear-gradient(to right, #e8344e, #ff6c7a);
  background: linear-gradient(to right, #e8344e, #ff6c7a);
  box-shadow: 0 10px 10px -2px rgba(0, 0, 0, 0.25);
  display: flex;
  align-items: center;

  ${({ theme }) => theme.tablet` 
    padding: 10px 24px 14px 24px;
    font-size: 16px;
  `};
`
