import React from "react"
import styled from "@emotion/styled"
import CtaButton from "./CtaButton"

const BannerInfo = props => {
  const goToSite = () => {
    window.open("https://career.spartacodingclub.kr/", "_blank")
  }

  return (
    <Container>
      <Title>
        스파르타에서
        <br />
        슈퍼 팀원을구합니다.
      </Title>
      <Text>
        스파르타 개발팀에서&nbsp;
        <Enter />
        슈퍼 개발자로 성장하기
      </Text>
      <CtaButton onClick={goToSite} />
    </Container>
  )
}

export default BannerInfo

const Container = styled.article`
  width: 100%;
  max-width: 1440px;
  margin: auto;
  height: 100%;
  padding: 54px 30px;
  color: white;
  line-height: 1.5;

  ${({ theme }) => theme.tablet` 
    padding: 96px 70px;   
  `};

  ${({ theme }) => theme.laptop` 
    padding: 96px 144px; 
 
  `};
`

const Title = styled.h1`
  font-weight: bold;
  font-size: 24px;
  margin-bottom: 10px;

  ${({ theme }) => theme.tablet` 
    font-size: 40px;
    margin-bottom: 16px;
  `};
`

const Text = styled.p`
  font-size: 16px;
  margin-bottom: 30px;

  ${({ theme }) => theme.tablet` 
    margin-bottom: 40px;  
  `};
`

const Enter = styled.br`
  ${({ theme }) => theme.tablet` 
    display: none;  
  `};
`
