import styled from "@emotion/styled"
import React from "react"
import PostList from "../organisms/PostList"

const ListTemplate = ({ pages }) => {
  return (
    <Container>
      <PostListWrapper>
        <PostList pages={pages} />
      </PostListWrapper>
    </Container>
  )
}

export default ListTemplate

const Container = styled.section`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
`

const PostListWrapper = styled.section`
  width: 100%;
  max-width: 1440px;
  margin: auto;
  padding: 0 16px;

  ${({ theme }) => theme.tablet` 
  padding: 0 30px;
`};

  ${({ theme }) => theme.laptop` 
  padding: 0 144px;
`};
`
